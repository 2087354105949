import { ArticleCardProps } from "@app/shared/types/article-card"

import { WordPressPost } from "./wordpress-blog"

const readMore = (lang: string): string => {
  switch (lang) {
    case "it":
      return "Continua a leggere"
    case "en":
      return "Continue reading"
    case "fr":
      return "Lire la suite"
    default:
      return "Continue reading"
  }
}

export const postToPreview = (post: WordPressPost, lang: string): ArticleCardProps => ({
  articlePreview: post.excerpt.rendered.replace(/(<([^>]+)>)/gi, "").replace("[&hellip;]", "..."),
  category: post.categories, //post.categories.length,
  continueReadingCtaText: readMore(lang),
  description: post.yoast_head_json.description,
  imageSrc: post?.yoast_head_json?.og_image?.[0]?.url || "",
  link: `/${lang}/blog/${post.slug}`,
  publicationDate: new Date(post.date).toLocaleDateString(lang, {
    day: "numeric",
    month: "long",
  }),
  title: post.title.rendered,
})
